<template>
    <div class="content-display">
        <div class="isItem" v-if="selectedItem">
            <h1>{{ selectedItem.title}}</h1>
            <p>{{ selectedItem.content }}</p>
        </div>
        <div v-else>
            <h1>Selecciona un elemento</h1>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            selectedItem: null,
        }
    },
    watch: {
        getSelectedItem: {
            handler: function (newVal) {
                this.selectedItem = newVal;
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        ...mapGetters('ModApps/tutor', { getSelectedItem: 'selectedItem' }),
    },
    mounted() {
        this.selectedItem = this.getSelectedItem;
    },
};
</script>

<style scoped>
.content-display {
    padding: 20px;
}
</style>
