<template>
  <div>
    <header-principal></header-principal>
    <div v-if="accessApp" class="row">
      <component v-if="getHeader" v-bind:is="component" @activarLateral="showAside = $event"></component>
      <div v-if="getHeader" v-bind:class="['page-wrapper', { show: showAside }, { isDesktop: isDesktop }]">
        <router-view></router-view>
      </div>
    </div>
    <!-- <footer-principal></footer-principal> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HeaderPrincipal from "@/core/views/Header";
import menulateral from "@/apps/tutor/components/Sidebar";
// import FooterPrincipal from "@/core/views/Footer";
export default {
  name: "TutorLayout",
  components: {
    HeaderPrincipal,
    menulateral,
    // FooterPrincipal,
  },
  data() {
    return {
      showAside: false,
      component: 'menulateral'
    };
  },
  created() {
    if (this.loggedIn && this.getHeader) {
      //console.log('si hay login M');
      this.validAccess();
    }
  },
  methods: {
    validAccess() {
      let t = this;
      t.$store.dispatch("core/apps/validAccess").then((response) => {
        let App = response;
        if (App) {
          //si tiene tutor asignado entonces permitir acceso
          console.log("Accesso permitido");
          t.$store.dispatch("ModApps/tutor/SetActive", App);
        } else {
          t.$store.dispatch("ModApps/tutor/SetActive", App);
          console.log("Accesso Negado");
          t.$router.push({ name: "Home" });
        } //sino volver a home
      });
    },
  },
  watch: {
    getHeader: function () {
      //console.log('getHeader ', this.getHeader);
      this.validAccess();
    },
  },
  computed: {
    ...mapGetters("ModApps/tutor", { accessApp: "accessApp" }),
    ...mapGetters("core", { getHeader: "getHeader" }),
    ...mapGetters("core/session", { loggedIn: "loggedIn" }),
    isDesktop() {
      console.log('isDesktop ', window.innerWidth>992);
      return window.innerWidth>992
    }
  },
};
</script>
<style scoped lang="scss">
.header {
  top: 0;
}

.page-wrapper,
.page-wrapper.show {
  margin-left: 220px !important;
}
.page-wrapper.isDesktop {
  width: 100%;
  // max-width: calc(100% - 330px);
}
</style>
