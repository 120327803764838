<template>
    <div>
        <!-- <div class="page-breadcrumb">
            <div class="row">
                <div class="col-12 align-self-center">
                    <h2 class="page-title">Billing Dashboard</h2>
                </div>
            </div>
        </div> -->
        <div class="container-fluid">
            <div class="row">    
                <div class="col-md-12">
                    <div class="alert alert-light" v-if="notiBilling">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            @click="closeNoti"
                        >
                            &times;
                        </button>
                        <strong
                            ><span class="badge badge-info b-msj"
                                ><i class="fa fa-info"></i
                            ></span>
                            {{ notiTitle }}</strong
                        >
                        {{ notiMsj }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-if="dash">
                    <div class="card card-body">
                        <div class="content" v-if="ShowHiden == true"> 
                            <!-- <div class="row alert alert-info" role="alert">
                                <div class="col-md-2"><i class="far fa-credit-card fa-5x" style="color: #555555;padding: 10px;"></i></div>
                                <div class="col-md-8">
                                    <h3><b style="color: #555555;">Start accepting online payments</b></h3>
                                    <p style="color: #555555;">
                                        Businesses are moving towargrds online payments as they re easey, secure<br>
                                        and fast. Trythem for your business today.
                                    </p>             
                                </div>
                                <div class="col-md-2">
                                    <button @click="enable()" class="btn btn-danger" style="position: absolute;top: 35%;left: 50%;">ENABLE</button>
                                </div>
                            </div>-->
                        </div>                    
                        <div class="row mt-xs-20">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <span class="cdropdown btnRinvoExp">
                                                <button>
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul>
                                                        <li @click="reporteInvoice(1)">
                                                            {{ botonAction[1] }}
                                                        </li>
                                                        <li @click="reporteInvoice(2)">
                                                            {{ botonAction[2] }}
                                                        </li>
                                                        <li @click="reporteInvoice(3)">
                                                            {{ botonAction[3] }}
                                                        </li>
                                                        <li @click="reporteInvoice(4)">
                                                            {{ botonAction[4] }}
                                                        </li>
                                                        <li @click="reporteInvoice(5)">
                                                            {{ botonAction[5] }}
                                                        </li>
                                                    </ul>
                                                </label>
                                            </span>
                                            <div class="col-md-12 col-md-12">
                                                <div class="row">
                                                    <div
                                                        class="alert alert-light col-md-3 c-sm-2"
                                                        style="padding: 0rem 0.8rem"
                                                    >
                                                        <div
                                                            class="row"
                                                            style="
                                                                border: 1px solid #ccc;
                                                                padding: 5px;
                                                            "
                                                        >
                                                            <label
                                                                class="text-success col-md-3 col-sm-3 tlabel"
                                                                style="padding: 0.4em 0"
                                                            >
                                                                Sales:
                                                            </label>
                                                            <h3
                                                                class="col-md-9 col-sm-9 text-left"
                                                            >
                                                                <span
                                                                    class="badge badge-light total_ipad"
                                                                >
                                                                    ${{
                                                                        totalSales
                                                                            | formatMoney
                                                                    }}
                                                                </span>
                                                            </h3>
                                                            <div
                                                                class="col-md-12"
                                                                style="padding: 0px"
                                                                v-if="thisYear !== null"
                                                            >
                                                                <GChart
                                                                    :settings="{
                                                                        packages: [
                                                                            'corechart',
                                                                        ],
                                                                    }"
                                                                    type="AreaChart"
                                                                    :data="dataChartInv"
                                                                    :options="
                                                                        OpInvoicesChart
                                                                    "
                                                                    v-if="!isLoading"
                                                                ></GChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="alert alert-light col-md-3 c-sm-2"
                                                        style="padding: 0rem 0.8rem"
                                                    >
                                                        <div
                                                            class="row"
                                                            style="
                                                                border: 1px solid #ccc;
                                                                padding: 5px;
                                                            "
                                                        >
                                                            <label
                                                                class="text-danger col-md-4 col-sm-3 tlabel"
                                                                style="padding: 0.4em 0"
                                                            >
                                                                Expenses:
                                                            </label>
                                                            <h3
                                                                class="col-md-8 col-sm-9 text-left"
                                                            >
                                                                <span
                                                                    class="badge badge-light total_ipad"
                                                                >
                                                                    ${{
                                                                        totalExpenses
                                                                            | formatMoney
                                                                    }}
                                                                </span>
                                                            </h3>
                                                            <div
                                                                class="col-md-12"
                                                                style="padding: 0px"
                                                                v-if="thisYear !== null"
                                                            >
                                                                <GChart
                                                                    :settings="{
                                                                        packages: [
                                                                            'corechart',
                                                                        ],
                                                                    }"
                                                                    type="AreaChart"
                                                                    :data="dataChartExp"
                                                                    :options="
                                                                        optionsChartExp
                                                                    "
                                                                    v-if="!isLoading"
                                                                ></GChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="alert alert-light col-md-2 c-md-3 c-sm-2"
                                                        style="padding: 0rem 0.8rem"
                                                    >
                                                        <div
                                                            class="row"
                                                            style="
                                                                border: 1px solid #ccc;
                                                                padding: 5px;
                                                            "
                                                        >
                                                            <label
                                                                class="text-info col-md-4 col-sm-3 tlabel"
                                                                style="padding: 0.4em 0"
                                                            >
                                                                Profit:
                                                            </label>
                                                            <h3
                                                                class="col-md-8 col-sm-9 text-left"
                                                            >
                                                                <span
                                                                    class="badge badge-light total_ipad"
                                                                >
                                                                    $
                                                                    {{
                                                                        totalProfit
                                                                            | formatMoney
                                                                    }}
                                                                </span>
                                                            </h3>
                                                            <div
                                                                class="col-md-12"
                                                                style="padding: 0px"
                                                                v-if="thisYear !== null"
                                                            >
                                                                <GChart
                                                                    :settings="{
                                                                        packages: [
                                                                            'corechart',
                                                                        ],
                                                                    }"
                                                                    type="AreaChart"
                                                                    :data="dataChartPro"
                                                                    :options="
                                                                        optionsChartPro
                                                                    "
                                                                    v-if="!isLoading"
                                                                ></GChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="alert alert-light col-md-4 c-md-3 c-sm-4"
                                                        style="padding: 0 15px"
                                                    >
                                                        <div class="row">
                                                            <label
                                                                class="text-success col-md-5 col-sm-3 md-text-right tlabel"
                                                                >Paid Invoices:</label
                                                            >
                                                            <h4
                                                                class="col-md-7 col-sm-9 text-left"
                                                            >
                                                                <span
                                                                    class="badge badge-light"
                                                                    >${{
                                                                        totalPaid
                                                                            | formatMoney
                                                                    }}
                                                                </span>
                                                            </h4>
                                                            <label
                                                                class="text-danger col-md-5 col-sm-3 md-text-right tlabel"
                                                                >Outstanding:</label
                                                            >
                                                            <h4
                                                                class="col-md-7 col-sm-9 text-left"
                                                            >
                                                                <span
                                                                    class="badge badge-light"
                                                                    >${{
                                                                        outstanding
                                                                            | formatMoney
                                                                    }}
                                                                </span>
                                                            </h4>
                                                            <div
                                                                class="col-md-12"
                                                                style="padding: 0px"
                                                                v-if="thisYear !== null"
                                                            >
                                                                <GChart
                                                                    :settings="{
                                                                        packages: [
                                                                            'corechart',
                                                                        ],
                                                                    }"
                                                                    type="AreaChart"
                                                                    :data="dataChartPay"
                                                                    :options="
                                                                        optionsChartPay
                                                                    "
                                                                    v-if="!isLoading"
                                                                ></GChart>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-md-12 graficoBarrasInvo"
                                                v-if="thisYear !== null"
                                            >
                                                <Loader v-if="isLoading"></Loader>
                                                <GChart
                                                    :settings="{
                                                        packages: [
                                                            'corechart',
                                                            'table',
                                                            'bar',
                                                        ],
                                                    }"
                                                    :type="typeChart"
                                                    :data="chartData"
                                                    :options="chartOptions"
                                                    :resizeDebounce="500"
                                                    v-else
                                                ></GChart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="margin-top: 20px">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div
                                        class="col-md-6 bg-white"
                                        style="padding-top: 20px; margin-bottom: 50px"
                                    >
                                        <h4 class="" style="font-weight: bold">
                                            Pending Estimates
                                        </h4>
                                        <table
                                            class="table table-striped table-bordered table-hover min"
                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col" style="width: 50px">
                                                        Est #
                                                    </th>
                                                    <th scope="col" style="width: 90px">
                                                        Date
                                                    </th>
                                                    <th scope="col">Customer</th>
                                                    <th
                                                        scope="col"
                                                        style="text-align: right"
                                                    >
                                                        Total
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        style="width: 60px"
                                                    ></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(i, index) in EstimatesList"
                                                    v-bind:key="index"
                                                >
                                                    <td>{{ i.numdoc }}</td>
                                                    <td>{{ i.date_create }}</td>
                                                    <td>
                                                        {{ i.customer_name }} <br />
                                                        {{ i.customer_bussinesname }}
                                                    </td>
                                                    <td style="text-align: right">
                                                        ${{ i.total | formatMoney }}
                                                    </td>
                                                    <td>
                                                        <span class="cdropdown">
                                                            <button
                                                                class="btnActionDashboard"
                                                            >
                                                                <span
                                                                    ><i
                                                                        class="fa fa-cog fa-lg"
                                                                    ></i
                                                                ></span>
                                                            </button>
                                                            <label>
                                                                <input type="checkbox" />
                                                                <ul>
                                                                    <li
                                                                        @click="
                                                                            pdfEst(i.id)
                                                                        "
                                                                    >
                                                                        <button>
                                                                            <i
                                                                                class="fas fa-file-pdf"
                                                                            ></i>
                                                                            Open PDF
                                                                        </button>
                                                                    </li>
                                                                    <li
                                                                        @click="
                                                                            editEst(i.id)
                                                                        "
                                                                    >
                                                                        <button>
                                                                            <i
                                                                                class="fas fa-edit"
                                                                            ></i>
                                                                            Edit
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </label>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-6"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-else>
                    <div class="card card-body"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Loader from "@/core/components/shared/Loader.vue";
import { GChart } from "vue-google-charts";
import { mapGetters } from "vuex";
export default {
    name: "DashboardBilling",
    components: {
        Loader,
        GChart,
    },
    data() {
        return {
            isLoading: true,
            notiBilling: false,
            accessBilling: false,
            notiTitle: "Title!",
            notiMsj:
                "Alert body Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore nulla aperiam soluta et quo, minus. Minima veniam magni iure, sapiente earum facere?",
            botonAction: [
                "Actions",
                "This Year",
                "Previous Year",
                "Last 12 Months",
                "This Month",
                "Previous Month",
            ],
            labelBntAction: "This Year",
            typeChart: "ColumnChart",
            chartData: [["Months", "Sales", "Expenses", "Outstanding"]],
            chartOptions: {
                //title:'Sales & Expenses',
                colors: ["#5ac146", "#fa5838", "#0065df"],
                height: "300",
                chartArea: { left: "7%", top: "5%", width: "85%", height: "250" },
                legend: { textStyle: { color: "#333", fontSize: 8 } },
                vAxis: { textStyle: { color: "#333", fontSize: 10 } },
                hAxis: { textStyle: { color: "#333", fontSize: 10 } },
            },
            dataChartInv: [["Invoice", "Total"]],
            OpInvoicesChart: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#5ac146"],
                hAxis: { title: "Invoices", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "60" },
            },
            dataChartExp: [["Expenses", "Amount"]],
            optionsChartExp: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#fa5838"],
                hAxis: { title: "Expenses", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "60" },
            },
            dataChartPro: [["Profit", "Profit"]],
            optionsChartPro: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#0065df"],
                hAxis: { title: "Profit", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "60" },
            },
            dataChartPay: [["Payments Received", "Payments"]],
            optionsChartPay: {
                //title:'Sales & Expenses',
                legend: { position: "none" },
                colors: ["#999"],
                hAxis: { title: "Payments Received", titleTextStyle: { color: "#333" } },
                tooltip: { textStyle: { fontSize: 8, color: "#333" } },
                height: "80",
                chartArea: { left: "30", top: "5", width: "80%", height: "60" },
            },
            thisYear: null,
            prevYear: null,
            thisYearOuts: null,
            prevYearOuts: null,
            prevMonthOuts: null,
            thisMonthOuts: null,
            doceMonthOuts: null,
            doceMonth: null,
            thisMonth: null,
            prevMonth: null,
            thisYearExp: null,
            prevYearExp: null,
            doceMonthExp: null,
            thisMonthExp: null,
            prevMonthExp: null,
            doceM: null,
            nomMonth: null,
            year: null,
            prevY: null,
            thisM: null,
            prevM: null,
            typeReporte: 1,
            totalSales: 0,
            totalExpenses: 0,
            totalProfit: 0,
            totalPaid: 0,
            outstanding: 0,
            windowWidth: 0,
            EstimatesList: [],
            ShowHiden:false,
        };
    },
    created() {
        this.accessBilling = this.$store.getters["ModApps/billing/access_billing"];
        console.log("this.accessBilling", this.accessBilling);
        this.getShowSMS();
        this.getSquareAccount()
    },
    methods: {
        getShowSMS(){
            window.billing
            .post('getKeyValSetting', {
                user_id: window.master_client,
                org_id: window.localorgdata,
                meta_key: 'showSMS',
            })
            .then((response) => {
                console.log(response.data);
                if(response.data.status){
                    if(response.data.r.meta_value ==0){
                        this.$store.commit('ModApps/billing/setShowSMS', 0); // ocultar
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        enable() {
            this.$router.push({ name: "squareAccount" });
        },
		getSquareAccount() {
			let t = this;
			window.master.post('getSquareUpAccount', {
				user_id: window.master_client,
				org_id: window.localorgdata,
			}).then((response) => {
				if(response.data.status) {
                    this.ShowHiden = false
				} else {
					this.ShowHiden = true
				}
				t.isLoading = false;
			}).catch((error) => {
				console.log(error);
			});
		},        
        reporteInvoice(num) {
            this.typeReporte = num;
            this.isLoading = true;
            window.billing
                .post("reporte/invoice", { 
                    user_id: window.localuserdata,
                    type: this.typeReporte
                })
                .then((response) => {
                    this.thisYear = response.data.thisYear;
                    this.prevYear = response.data.prevYear;
                    this.thisYearOuts = response.data.thisYearOuts;
                    this.prevYearOuts = response.data.prevYearOuts;
                    this.prevMonthOuts = response.data.prevMonthOuts;
                    this.thisMonthOuts = response.data.thisMonthOuts;
                    this.doceMonthOuts = response.data.doceMonthOuts;
                    this.doceMonth = response.data.doceMonth;
                    this.thisMonth = response.data.thisMonth;
                    this.prevMonth = response.data.prevMonth;
                    this.thisYearPay = response.data.thisYearPay;
                    this.prevYearPay = response.data.prevYearPay;
                    this.doceMonthPay = response.data.doceMonthPay;
                    this.thisMonthPay = response.data.thisMonthPay;
                    this.prevMonthPay = response.data.prevMonthPay;
                    this.thisYearExp = response.data.thisYearExp;
                    this.prevYearExp = response.data.prevYearExp;
                    this.doceMonthExp = response.data.doceMonthExp;
                    this.thisMonthExp = response.data.thisMonthExp;
                    this.prevMonthExp = response.data.prevMonthExp;
                    this.doceM = response.data.doceM;
                    this.nomMonth = response.data.nomMonth;
                    this.year = response.data.year;
                    this.prevY = response.data.prevY;
                    this.thisM = response.data.thisM;
                    this.prevM = response.data.prevM;
                    this.rInvExp(this.typeReporte);
                }).catch((error) => {
                    console.log('error', error);
                    this.isLoading = false;
                });
        },
        closeNoti() {
            this.notiBilling = false;
        },
        rInvExp(num) {
            this.typeReporte = num;
            this.labelBntAction = this.botonAction[num];
            this.isLoading = true;
            this.chartData = [["Months", "Sales", "Expenses", "Outstanding"]];
            this.chartOptions.hAxis.title = "";
            this.totalSales = 0;
            this.totalExpenses = 0;
            this.totalProfit = 0;
            this.totalPaid = 0;
            this.outstanding = 0;
            this.dataChartInv = [["Invoice", "Total"]];
            this.dataChartExp = [["Expenses", "Amount"]];
            this.dataChartPro = [["Profit", "Profit"]];
            this.dataChartPay = [["Payments Received", "Payments"]];
            if (this.typeReporte == 0 || this.typeReporte == 1) {
                for (let i in this.thisYear) {
                    this.chartData.push([
                        this.nomMonth[i] + "\n" + this.year,
                        parseFloat(this.thisYear[i]),
                        parseFloat(this.thisYearExp[i]),
                        parseFloat(this.thisYearOuts[i]),
                    ]);
                    this.totalSales += parseFloat(this.thisYear[i]);
                    this.totalExpenses += parseFloat(this.thisYearExp[i]);
                    this.totalPaid += parseFloat(this.thisYearPay[i]);

                    if (isNaN(this.thisYear[i])) {
                        this.thisYear[i] = 0;
                    }
                    if (this.thisYear[i] > 0) {
                        this.dataChartInv.push([
                            this.nomMonth[i],
                            parseFloat(this.thisYear[i]),
                        ]);
                    }

                    if (isNaN(this.thisYearExp[i])) {
                        this.thisYearExp[i] = 0;
                    }
                    if (this.thisYearExp[i] > 0) {
                        this.dataChartExp.push([
                            this.nomMonth[i],
                            parseFloat(this.thisYearExp[i]),
                        ]);
                    }
                    this.totalProfit =
                        parseFloat(this.thisYear[i]) - parseFloat(this.thisYearExp[i]);
                    if (isNaN(this.totalProfit)) {
                        this.totalProfit = 0;
                    }
                    if (this.totalProfit > 0) {
                        this.dataChartPro.push([
                            this.nomMonth[i],
                            parseFloat(this.totalProfit),
                        ]);
                    }
                    if (isNaN(this.thisYearPay[i])) {
                        this.thisYearPay[i] = 0;
                    }
                    if (this.thisYearPay[i] > 0) {
                        this.dataChartPay.push([
                            this.nomMonth[i],
                            parseFloat(this.thisYearPay[i]),
                        ]);
                    }
                }
            } else if (this.typeReporte == 2) {
                for (let i in this.prevYear) {
                    this.chartData.push([
                        this.nomMonth[i] + "\n" + this.prevY,
                        parseFloat(this.prevYear[i]),
                        parseFloat(this.prevYearExp[i]),
                        parseFloat(this.prevYearOuts[i]),
                    ]);
                    this.totalSales += parseFloat(this.prevYear[i]);
                    this.totalExpenses += parseFloat(this.prevYearExp[i]);
                    this.totalPaid += parseFloat(this.prevYearPay[i]);

                    if (isNaN(this.prevYear[i])) {
                        this.prevYear[i] = 0;
                    }
                    if (this.prevYear[i] > 0) {
                        this.dataChartInv.push([
                            this.nomMonth[i],
                            parseFloat(this.prevYear[i]),
                        ]);
                    }
                    if (isNaN(this.prevYearExp[i])) {
                        this.prevYearExp[i] = 0;
                    }
                    if (this.prevYearExp[i] > 0) {
                        this.dataChartExp.push([
                            this.nomMonth[i],
                            parseFloat(this.prevYearExp[i]),
                        ]);
                    }
                    this.totalProfit =
                        parseFloat(this.prevYear[i]) - parseFloat(this.prevYearExp[i]);
                    if (isNaN(this.totalProfit)) {
                        this.totalProfit = 0;
                    }
                    if (this.totalProfit > 0) {
                        this.dataChartPro.push([
                            this.nomMonth[i],
                            parseFloat(this.totalProfit),
                        ]);
                    }
                    if (isNaN(this.prevYearPay[i])) {
                        this.prevYearPay[i] = 0;
                    }
                    if (this.prevYearPay[i] > 0) {
                        this.dataChartPay.push([
                            this.nomMonth[i],
                            parseFloat(this.prevYearPay[i]),
                        ]);
                    }
                }
            } else if (this.typeReporte == 3) {
                for (let i in this.doceMonth) {
                    //console.log('prop '+i, 'value '+parseFloat(this.doceMonth[i]));
                    this.chartData.push([
                        this.doceM[i],
                        parseFloat(this.doceMonth[i]),
                        parseFloat(this.doceMonthExp[i]),
                        parseFloat(this.doceMonthOuts[i]),
                    ]);
                    this.totalSales += parseFloat(this.doceMonth[i]);
                    this.totalExpenses += parseFloat(this.doceMonthExp[i]);
                    this.totalPaid += parseFloat(this.doceMonthPay[i]);

                    if (isNaN(this.doceMonth[i])) {
                        this.doceMonth[i] = 0;
                    }
                    if (this.doceMonth[i] > 0) {
                        this.dataChartInv.push([
                            this.doceMonth[i],
                            parseFloat(this.doceMonth[i]),
                        ]);
                    }
                    if (isNaN(this.doceMonthExp[i])) {
                        this.doceMonthExp[i] = 0;
                    }
                    if (this.doceMonthExp[i] > 0) {
                        this.dataChartExp.push([
                            this.doceMonth[i],
                            parseFloat(this.doceMonthExp[i]),
                        ]);
                    }
                    this.totalProfit =
                        parseFloat(this.doceMonth[i]) - parseFloat(this.doceMonthExp[i]);
                    if (isNaN(this.totalProfit)) {
                        this.totalProfit = 0;
                    }
                    if (this.totalProfit > 0) {
                        this.dataChartPro.push([
                            this.doceMonth[i],
                            parseFloat(this.totalProfit),
                        ]);
                    }
                    if (isNaN(this.doceMonthPay[i])) {
                        this.doceMonthPay[i] = 0;
                    }
                    if (this.doceMonthPay[i] > 0) {
                        this.dataChartPay.push([
                            this.doceMonth[i],
                            parseFloat(this.doceMonthPay[i]),
                        ]);
                    }
                }
            } else if (this.typeReporte == 4) {
                this.chartData = [["Days", "Sales", "Expenses", "Outstanding"]];
                this.chartOptions.hAxis.title = this.thisM + ", " + this.year;
                for (let i in this.thisMonth) {
                    if (
                        parseFloat(this.thisMonth[i]) > 0 ||
                        parseFloat(this.thisMonthExp[i]) > 0 ||
                        parseFloat(this.thisMonthOuts[i]) > 0
                    ) {
                        this.chartData.push([
                            "Day " + i,
                            parseFloat(this.thisMonth[i]),
                            parseFloat(this.thisMonthExp[i]),
                            parseFloat(this.thisMonthOuts[i]),
                        ]);
                        this.totalSales += parseFloat(this.thisMonth[i]);
                        this.totalExpenses += parseFloat(this.thisMonthExp[i]);
                    }
                    if (isNaN(this.thisMonth[i])) {
                        this.thisMonth[i] = 0;
                    }
                    if (this.thisMonth[i] > 0) {
                        this.dataChartInv.push([
                            "Day " + i,
                            parseFloat(this.thisMonth[i]),
                        ]);
                    }
                    if (isNaN(this.thisMonthExp[i])) {
                        this.thisMonthExp[i] = 0;
                    }
                    if (this.thisMonthExp[i] > 0) {
                        this.dataChartExp.push([
                            "Day " + i,
                            parseFloat(this.thisMonthExp[i]),
                        ]);
                    }
                    this.totalProfit =
                        parseFloat(this.thisMonth[i]) - parseFloat(this.thisMonthExp[i]);
                    if (isNaN(this.totalProfit)) {
                        this.totalProfit = 0;
                    }
                    if (this.totalProfit > 0) {
                        this.dataChartPro.push([
                            "Day " + i,
                            parseFloat(this.totalProfit),
                        ]);
                    }
                    this.totalPaid += parseFloat(this.thisMonthPay[i]);
                    if (isNaN(this.thisMonthPay[i])) {
                        this.thisMonthPay[i] = 0;
                    }
                    if (this.thisMonthPay[i] > 0) {
                        this.dataChartPay.push([
                            "Day " + i,
                            parseFloat(this.thisMonthPay[i]),
                        ]);
                    }
                }
            } else if (this.typeReporte == 5) {
                this.chartData = [["Days", "Sales", "Expenses", "Outstanding"]];
                this.chartOptions.hAxis.title = this.prevM + ", " + this.year;
                this.totalSales = 0;
                this.totalExpenses = 0;
                for (let i in this.prevMonth) {
                    if (
                        parseFloat(this.prevMonth[i]) > 0 ||
                        parseFloat(this.prevMonthExp[i]) > 0 ||
                        parseFloat(this.prevMonthOuts[i]) > 0 
                    ) {
                        this.chartData.push([
                            "Day " + i,
                            parseFloat(this.prevMonth[i]),
                            parseFloat(this.prevMonthExp[i]),
                            parseFloat(this.prevMonthOuts[i]),
                        ]);
                    }
                    this.totalSales += parseFloat(this.prevMonth[i]);
                    this.totalExpenses += parseFloat(this.prevMonthExp[i]);
                    this.totalPaid += parseFloat(this.prevMonthPay[i]);
                    console.log(i, this.prevMonthPay[i], this.totalPaid);
                    if (isNaN(this.prevMonth[i])) {
                        this.prevMonth[i] = 0;
                    }
                    if (this.prevMonth[i] > 0) {
                        this.dataChartInv.push([
                            "Day " + i,
                            parseFloat(this.prevMonth[i]),
                        ]);
                    }
                    if (isNaN(this.prevMonthExp[i])) {
                        this.prevMonthExp[i] = 0;
                    }
                    if (this.prevMonthExp[i] > 0) {
                        this.dataChartExp.push([
                            "Day " + i,
                            parseFloat(this.prevMonthExp[i]),
                        ]);
                    }
                    this.totalProfit =
                        parseFloat(this.prevMonth[i]) - parseFloat(this.prevMonthExp[i]);
                    if (isNaN(this.totalProfit)) {
                        this.totalProfit = 0;
                    }
                    if (this.totalProfit > 0) {
                        this.dataChartPro.push([
                            "Day " + i,
                            parseFloat(this.totalProfit),
                        ]);
                    }
                    if (isNaN(this.prevMonthPay[i])) {
                        this.prevMonthPay[i] = 0;
                    }
                    if (this.prevMonthPay[i] > 0) {
                        this.dataChartPay.push([
                            "Day " + i,
                            parseFloat(this.prevMonthPay[i]),
                        ]);
                    }
                }
            }
            if (isNaN(this.totalSales)) {
                this.totalSales = 0;
            }
            if (isNaN(this.totalExpenses)) {
                this.totalExpenses = 0;
            }
            if (isNaN(this.totalPaid)) {
                this.totalPaid = 0;
            }
            if (isNaN(this.outstanding)) {
                this.outstanding = 0;
            }
            this.totalProfit =
                parseFloat(this.totalSales) - parseFloat(this.totalExpenses);
            this.outstanding = parseFloat(this.totalSales) - parseFloat(this.totalPaid);
            if (isNaN(this.totalProfit) || this.totalProfit < 0) {
                this.dataChartPro = [["Profit", "Profit"]];
                this.dataChartPro.push(["None", this.totalProfit]);
            }
            if (!isNaN(this.totalSales) && this.totalSales == 0) {
                this.dataChartInv = [["Invoice", "Total"]];
                this.dataChartInv.push(["None", 0]);
                this.dataChartPro = [["Profit", "Profit"]];
                this.dataChartPro.push(["None", 0]);
            }
            if (!isNaN(this.totalExpenses) && this.totalExpenses == 0) {
                this.dataChartExp = [["Expenses", "Amount"]];
                this.dataChartExp.push(["None", 0]);
            }
            if (!isNaN(this.totalPaid) && this.totalPaid == 0) {
                this.dataChartPay = [["Payments Received", "Payments"]];
                this.dataChartPay.push(["None", 0]);
            }
            if (
                !isNaN(this.totalSales) &&
                this.totalSales == 0 &&
                !isNaN(this.totalExpenses) &&
                this.totalExpenses == 0
            ) {
                this.chartData.push(["", 0, 0, 0]);
            }
            this.isLoading = false;
        },
        getEstimates() {
            this.isLoading = true;
            window.billing
                .get("reporte/estimatespending/" + window.localuserdata)
                .then((response) => {
                    this.EstimatesList = response.data.result;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        pdfEst(id) {
            window.open(window.urlbase + "api/v2/estimate/pdf/" + id, "_blank");
        },
        editEst(id) {
            this.$router.push({ name: "estproFormEdit", params: { idest: id } });
        },
        access(a) {
            this.accessBilling = a;
        },
    },
    watch: {
        isLoading: function () {
            this.$store.commit("set_isLoading", this.isLoading);
        },
        showAside: function () {
            // console.log(this.showAside)
            // this.reporteInvoice();
        },
        accessBilling: function () {
            if (this.access_billing) {
                if (this.dash) {
                    console.log("Dashboard");
                    this.reporteInvoice(1);
                    this.getEstimates();
                } else {
                    console.log("No Dashboard");
                }
            }
        },
        access_billing: function () {
            if (this.access_billing) {
                this.accessBilling = this.access_billing;
                if (this.dash) {
                    console.log("Dashboard");
                    this.reporteInvoice();
                    this.getEstimates();
                } else {
                    console.log("No Dashboard");
                }
            }
        },
    },
    computed: {
        ...mapGetters("core", { showAside: "getShowAside" }),
        ...mapGetters("ModApps/billing", { dash: "get_dashboard" }),
        ...mapGetters("ModApps/billing", { access_billing: "access_billing" }),
    },
};
</script>
<style lang="scss" scoped>
.min thead tr th,
table .min tfoot tr th {
    background: #eee;
    color: #000;
    border: 1px solid #ddd;
    vertical-align: middle;
    text-align: center;
    padding: 5px 10px;
    position: relative;
    font-size: 12px;
    font-weight: bold;
}
.min tbody tr td {
    font-size: 0.775rem;
}
.btnActionDashboard {
    font-size: 12px;
}
span.badge.badge-info.b-msj {
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 10px;
}
.md-text-right {
    text-align: right;
}
.c-md-3 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
}

span.cdropdown.btnRinvoExp {
    position: absolute;
    z-index: 1;
    right: 0;
}
span.cdropdown.btnRinvoExp button {
    border: 0px;
    color: #000;
    padding-right: 20px;
    padding-left: 5px;
    font-size: 14px;
}
span.cdropdown.btnRinvoExp button:before {
    border-top: 5px solid transparent;
    content: initial;
}
.select-filtro {
    margin-bottom: 10px !important;
    border: 1px solid transparent;
    max-width: 15% !important;
    width: 15%;
    border-bottom: 1px solid #999 !important;
    border-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    display: inline-block;
    font-size: 16px;
    height: 43px !important;
    color: #728192;
}
.graficoBarrasInvo text {
    font-size: 10px !important;
}
@media (max-width: 1190px) {
    .tlabel {
        font-size: 12px;
    }
    span.badge.badge-light.total_ipad {
        font-size: 65%;
    }
    .select-filtro {
        max-width: 20% !important;
        width: 20% !important;
    }
    .md-text-right {
        text-align: left;
    }

    .c-sm-2 {
        flex: 0 0 24% !important;
        max-width: 24% !important;
    }
    .c-sm-4 {
        flex: 0 0 27.33333% !important;
        max-width: 27.33333% !important;
    }
}

@media (max-width: 992px) {
    .select-filtro {
        max-width: 20% !important;
        width: 20% !important;
    }
}
@media (max-width: 899px) {
    .select-filtro {
        max-width: 25% !important;
        width: 25% !important;
    }
    .md-text-right {
        text-align: left;
    }

    .c-sm-2 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .c-sm-4 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
}
@media (max-width: 768px) {
    .select-filtro {
        max-width: 30% !important;
        width: 30% !important;
    }
    .md-text-right {
        text-align: left;
    }

    .c-sm-2 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .c-sm-4 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    span.cdropdown.btnRinvoExp {
        right: 0px;
        top: -40px;
    }
    span.cdropdown.btnRinvoExp button {
        width: 10px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .mt-xs-20 {
        margin-top: 20px;
    }
}
@media (max-width: 450px) {
    .select-filtro {
        max-width: 100% !important;
        width: 100% !important;
        padding-left: 0px;
    }
}
ul.menuTop.menuApps li.dropdown-item:nth-child(2) {
    background-color: #019720 !important;
}
@media (max-width: 767px) {
    span.cdropdown.btnRinvoExp ul li {
        min-width: 160px;
        font-size: 20px;
    }
}
</style>
