<template>
    <div class="col-lg-2 col-md-12 date-status">
        <div class="row" >
            <div class="form-group col-lg-12 col-md-6" v-if="form.date_created!=null">
                <!-- <label for="datedocInput">Document Date</label> -->
                <div :class="['date-label', (disabledSteps.customer) ? 'disabled' : '']">
                    {{ form.date_created | moment("MMM DD, YYYY") }}
                </div>
                <input type="text" class="form-control" v-model="form.date_created" required="true" disabled
                    style="display:none;" />
            </div>
            <div class="form-group col-lg-12 col-md-6" v-if="form.status!=null">
                <!-- <label for="statusInput">Status</label> -->
                <div class="fillStatus">
                    <span class="cdropdown" v-if="disabledSteps.customer">
                        <button type="button" class="btn-status disabled">
                            <span style="">Disabled</span>
                        </button>
                    </span>
                    <span class="cdropdown" v-else>
                        <button type="button" :class="['btn-status',
                            (form.status == 1) ? 'pending' :
                                (form.status == 2) ? 'accepted' :
                                    (form.status == 3) ? 'rejected' :
                                        (form.status == 4) ? 'void' :
                                            (form.status == 5) ? 'signed' : 'inactivo'
                        ]">
                            <span v-for="(i, idx) in statusdata" :key="idx"
                                :style="(form.status != i.value) ? 'display:none;' : ''">
                                {{ i.name }}
                            </span>
                        </button>
                        <label>
                            <input type="checkbox" id="statusInput" />
                            <ul class="actions-status">
                                <li v-for="(i, idx) in statusdata" :key="idx"
                                    :style="(form.status != i.value && i.value != 5 && i.value != 4) ? '' : 'display:none;'"
                                    @click="changeStatus(i.value)">
                                    {{ i.name }}
                                </li>
                            </ul>
                        </label>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    props: [
        "controlLoad",
        "propdisabledSteps",
        "propForm",
    ],
    data() {
        return {
            disabledSteps: this.propdisabledSteps,
            form: {
                status: null,
                date_created: null,
            },
            statusdata: [
                { value: "1", name: "Pending" },
                { value: "2", name: "Accepted" },
                { value: "3", name: "Rejected" },
                { value: "4", name: "Void" },
                { value: "5", name: "Signed" },
            ],
        }
    },
    created() {
        let t = this;
        this.form.status = this.propForm.status;
        this.form.date_created = this.propForm.date_created;
    },
    watch : {
        controlLoad: function(){
            let t = this;
            if(this.controlLoad){
                this.form.status = this.propForm.status;
                this.form.date_created = this.propForm.date_created;
            }
        },
    },
    methods: {
        changeStatus(val){
            let t = this;
            t.form.status = val;
            t.updateData()
        },
        updateData(){
            let t = this;
            t.$emit('updateProps', {
                "status": t.form.status,
                "date_created": t.form.date_created,
            })
        },
    },
};
</script>
