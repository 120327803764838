<template>
    <div class="col-12">
        <div class="form-inline row">
            <div v-if="form.checked" class="form-group" style="margin-right: 5px;margin-left: 15px;">
                <input type="text" class="form-control" style="margin-bottom:0px;width:250px;" v-model="selectCTemplate.title"
                    placeholder="Terms & Conditions" />
            </div>
            <div v-if="form.checked" class="form-group">
                <select v-model="selectedContract" class="form-control my-1 mr-2" id="exampleFormControlSelect1"
                    style="width: 300px;margin-top: 10px;">
                    <option :value="{ title: 'Contract Title', description: '' }" selected>
                        Select template
                    </option>
                    <option v-for="i in ContractTemLists" :key="i.id" :value="i">
                        {{ i.title }}
                        <span v-if="i.alias !== null">- {{ i.alias }}</span>
                    </option>
                </select>
            </div>
            <div class="my-1 mr-2" v-if="form.checked && PlanPremium">
                Request Digital Signature?
            </div>
            <div v-if="form.checked && PlanPremium" class="custom-control custom-checkbox my-1 mr-sm-2">
                <input type="checkbox" class="custom-control-input" id="signe" v-model="form.signe" @change="setUpdate"/>
                <label class="custom-control-label" for="signe">Yes</label>
            </div>
        </div>
        <vue-editor v-model="selectCTemplate.description" v-if="form.checked" @text-change="setUpdate"/>
        <h5 style="padding-top: 20px" v-if="form.checked"></h5>
        <div class="row" v-if="form.checked">
            <div class="col">
                <h4> Signature Receiver:</h4>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Customer</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" v-model="form.customer_name" @change="setUpdate"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-2 col-form-label">Date</label>
                    <div class="col-sm-10">
                        <div :class="['date-label', (disabledSteps.customer) ? 'disabled' : '']">
                            {{ FormSignature.dateCustomer | moment("MMM DD, YYYY") }}
                        </div>
                        <input type="text" class="form-control" v-model="FormSignature.dateCustomer" required="true"
                            disabled style="display:none;" />
                    </div>
                </div>
            </div>
            <div class="col">
                <h4>Signature Submitter:</h4>
                <div class="form-group row text-left">
                    <label class="col-sm-2 col-form-label">Company</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" v-model="FormSignature.nameCompany" @change="setUpdate"/>
                    </div>
                </div>
                <div class="form-group row text-left">
                    <label for="" class="col-sm-2 col-form-label">Date</label>
                    <div class="col-sm-10">
                        <div :class="['date-label', (disabledSteps.customer) ? 'disabled' : '']">
                            {{ FormSignature.dateCompany | moment("MMM DD, YYYY") }}
                        </div>
                        <input type="text" class="form-control" v-model="FormSignature.dateCompany" required="true"
                            disabled style="display:none;" @change="setUpdate"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment';
import { VueEditor } from 'vue2-editor'
export default {
    components: {
        VueEditor,
    },
    props: [
        "propForm",
        "propdisabledSteps",
        "propFormSignature",
        "propSelectCTemplate",
    ],
    data() {
        return {
            ContractTemLists: [],
            disabledSteps: this.propdisabledSteps,
            form: this.propForm,
            FormSignature: this.propFormSignature,
            selectCTemplate: this.propSelectCTemplate,
            prevSelectCTemplate: this.propSelectCTemplate,
            PlanPremium: true,
            contractTemplateDefault: { title: 'Contract Title', description: '' },
            selectedContract: { title: 'Contract Title', description: '' },
            contractCustom: false,
        };
    },
    created(){
        let t = this;
        // t.PlanPremium = (t.$store.getters['core/Org/getPlanId'] > 1);
        t.getCTemplates();
    },
    methods: {
        getCTemplates() {
            window.billing
            .get("contracttemplate/" + window.localuserdata)
            .then((response) => {
                // console.log("template estimates list");
                // console.log(response.data);
                this.ContractTemLists = response.data.result;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        setUpdate(){
            this.$emit('updateProp', {
                "FormSignature": this.FormSignature,
                "selectCTemplate": this.selectCTemplate,
                "signe": this.form.signe,
                "customer_name": this.form.customer_name,
            });
        },
    },
    watch: {
        selectCTemplate: {
            handler: function(newVal){
                let t = this;
                t.selectedContract = newVal;
                const isChoose = t.ContractTemLists.some(item => item.description === newVal.description);
                // console.log('isChoose', isChoose)
                if(!isChoose){
                    // si entra en esta condicion quiere decir q no ha seleccionando ningun contrato de la lista o lo ha personalizado
                    t.contractCustom=true;
                    t.selectedContract = t.contractTemplateDefault; // para no mostrar el select en blanco asigno un valor default
                }
            },
            deep: true, // Necesario para observar cambios en los objetos dentro del array
            immediate: true, // Llamar al watch inmediatamente al cargar
        },
        selectedContract: function(){
            let t = this;
            if(t.selectedContract != t.contractTemplateDefault){
                t.selectCTemplate.description = t.selectedContract.description;
            }else{
                t.selectCTemplate.description = t.prevSelectCTemplate.description;
            }
        },
        getPlanId() {
            this.PlanPremium = (this.$store.getters['core/Org/getPlanId'] > 1);
            // console.log('watch PlanPremium', this.PlanPremium);
        },
    },
    computed: {
        ...mapGetters("core/Org", { getPlanId: "getPlanId"}),
    },
};
</script>
